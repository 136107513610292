<template>
  <LandingHeader />
  <div class="container-white-background landing-page-content">
    <div class="container">
      <Separator size="150" />
      <TextSimple :comp="firstText" />
      <Separator size="75" />
      <ClassicTeaser :teaser="firstTeaser" />
      <Separator size="75" />
      <ClassicTeaser :teaser="secondTeaser" />

      <!-- TARIFE SERVICII PRICES SERVICES -->
      <!-- <Separator size="75" />
      <div class="services">
        <div class="cards mobile-cards" v-if="services.length > 0">
          <Card v-for="(service, index) in services.slice(0, 5)" :key="index" :service="service" />

          <div class="cmp-service-card service-more-card">
            <router-link class="nav-anchor" to='/servicii-dentare'>
              <div class="service-more-card-content">
                <Icon :size="100" color="rgb(112, 49, 117)" icon="arrow-right" />
                <h3>Mai mult</h3>
              </div>
            </router-link>
          </div>
        </div>
        <div class="cards desktop-cards" v-if="services.length > 0">
          <Card v-for="(service, index) in services.slice(0, 7)" :key="index" :service="service" />

          <div class="cmp-service-card service-more-card">
            <router-link class="nav-anchor" to='/servicii-dentare'>
              <div class="service-more-card-content">
                <Icon :size="100" color="rgb(112, 49, 117)" icon="arrow-right" />
                <h3>Mai mult</h3>
              </div>
            </router-link>
          </div>
        </div>
      </div> -->
      <Separator size="50" />
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import ClassicTeaser from "../components/subcomponents/ClassicTeaser.vue";
import Icon from "@/components/Icon.vue";
import Title from "../components/subcomponents/Title.vue";
import TextSimple from "../components/subcomponents/TextSimple.vue";
import LandingHeader from "../components/LandingHeader.vue";
import LandingKeys from "@/components/LandingKeys.vue";
import Separator from "@/components/Separator.vue";

import { useServicesStore } from "../stores/ServicesStore";

import { ref } from "@vue/reactivity";

export default {
  components: {
    Card,
    ClassicTeaser,
    Icon,
    LandingHeader,
    LandingKeys,
    Separator,
    TextSimple,
    Title,
  },
  setup() {
    const firstText = ref({
      text: "Situat în Cartierul Chercea, pe Str. Sfânta Filofteia, nr. 29, cabinetul VisoDent îmbină profesionalismul cu pasiunea. Fiecare pacient se bucură de o îngrijire personalizată, cu o gamă largă de servicii începând de la prevenție și până la cosmetică dentară. Prioritatea noastră numărul 1 este sănătatea dumneavoastră dentară, iar pentru a ne îndeplini misiunea vă punem la dispoziție expertiza și angajamentul echipei noastre."
    });

    const firstTeaser = ref({
      alt: "Imagine cu doctori",
      ideeDeFoto: "recomand o imagine care să transmită profesionalism, confort și zâmbete strălucitoare. De exemplu, puteți alege o imagine cu un medic stomatolog zâmbitor, purtând echipamentul adecvat, în timp ce interacționează cu un pacient fericit și relaxat într-un cadru modern și primitor. Aceasta ar putea transmite un sentiment de încredere și satisfacție în privința serviciilor oferite de clinica stomatologică Visodent.",
      image:
        "https://images.unsplash.com/photo-1606913536019-510fdc8f775e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2525&q=80",
      paragraphs: [
        "Aspiri la zâmbetul perfect? Suntem aici pentru a transforma acest vis în realitate. Cu abilitate profesională și precizie elvețiană, echipa noastră de stomatologi devotați vă așteaptă într-o atmosferă confortabilă și primitoare pentru a evalua starea sănătății dumneavoastră dentare. Împreună vom discuta variantele de tratament și vom identifica soluții rapide pentru problemele cu care vă confruntați. La VisoDent vă asigurăm că veți obține rezultatele mult dorite în cel mai scurt timp posibil.",
      ],
      title: "Secretul zâmbetului perfect!",
      isLeft: true,
    });

    const secondTeaser = ref({
      alt: "Imagine cu doctori",
      ideeDeFoto: "Pentru a ilustra mesajul de 'excelență și confort în serviciile stomatologice', puteți considera utilizarea unei imagini care prezintă echipa noastră de medici stomatologi, cu zâmbete prietenoase și încredere profesională, în timp ce lucrează cu tehnologie de vârf. Aceasta poate fi combinată cu o imagine care sugerează un mediu relaxant și primitor, cum ar fi o încăpere spațioasă și luminată, cu design modern și confortabil. Scopul este să transmită clienților potențiali un sentiment de încredere, profesionalism și confort în timpul vizitelor lor la VISODENT.",
      image:
        "https://images.unsplash.com/photo-1606913536019-510fdc8f775e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2525&q=80",
      paragraphs: [
        "Echipa de profesioniști medicali din cadrul cabinetului se dedică constant formării și specializării pentru a vă putea oferi servicii de înaltă calitate. Îmbinând expertiza cu tehnologia de ultimă generație din dotare, garantăm precizie pentru fiecare procedură, indiferent de complexitatea acesteia și suntem pregătiți să vă oferim servicii stomatologice la cel măi înalt nivel, într-un mediu confortabil, liniștit și lipsit de stres.",
      ],
      title: "Excelență și confort",
      isLeft: false,
    });

    const services = useServicesStore().services;

    return { firstText, firstTeaser, secondTeaser, services };
  },
};
</script>
