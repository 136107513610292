<template>
  <div v-if="showProcedures" class="procedures">
    <div class="procedures-list">
      <div class="procedure" v-for="pr in procedures" :key="pr.index">
        {{pr.index}}. {{ pr.text }}
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from "@vue/reactivity";
import { onMounted } from 'vue';

export default {
  setup() {
    const showProcedures = ref(false);
    const procedures = ref([]);

    const sheetId = '1W-V9WOpwyX9RWH8JV2t47D3GlQGSXbrjKHexm-1Nn7w';
    const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
    const sheetName = 'proceduri';
    const query = encodeURIComponent('Select *')
    const url = `${base}&sheet=${sheetName}&tq=${query}`

    onMounted(async () => {
      const data = [];

      fetch(url)
        .then(res => res.text())
        .then(rep => {
          const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
          jsonData.table.rows.forEach((rowData) => {
            // if (!foundCat.includes(rowData.c[0].v.toLowerCase())) {
            //   foundCat.push(rowData.c[0].v.toLowerCase());
            // }
            const procedure = {
              index: rowData.c[0].v,
              text: rowData.c[1].v,
            };
            data.push(procedure);
          })
          showProcedures.value = true;
          procedures.value = data;
        })
    })

    return { procedures, showProcedures };
  },
};
</script>