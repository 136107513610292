<template>
  <div class="updating-website">
    <div class="updating-website-content">
      <h1>Actualizam website-ul</h1>
      <p>
        Momentan lucrăm la îmbunătățirea website-ului nostru. Vă rugăm să
        reveniți mai târziu.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.updating-website {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 370px);
  background-color: white;

  h1 {
    font-size: 2rem;
    text-align: center;
  }
  p {
    text-align: center;
  }
}
</style>