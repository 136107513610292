import { defineStore } from "pinia";

export const useNavPagesStore = defineStore("navPagesStore", {
  state: () => ({
    pages: [
      { url: "", title: "Acasa" },
      { url: "despre-cabinet", title: "Despre noi" },
      // { url: "servicii-dentare", title: "Servicii" },
      { url: "proceduri", title: "Proceduri" },
      // TARIFE SERVICII PRICES SERVICES
      // { url: "tarife", title: "Tarife" },
      // { url: "personal", title: "Personal" },
      // { url: "programari", title: "Programări" },
      { url: "contact", title: "Contact" },
    ],
  }),
});
