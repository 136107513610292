<template>
  <PageHead :img="headImg" :title="headTitle" :subtitle="subtitle" />
  <div class="container-white-background">
    <Separator :size="75" />
    <div id="prices-page" class="container">
      <ProceduresList />
    </div>
    <Separator :size="75" />
  </div>
</template>
  
<script>
import { ref } from "@vue/reactivity";
import PageHead from "../components/PageHead.vue";
import ProceduresList from "@/components/procedures/ProceduresList.vue";
import Separator from "@/components/Separator.vue";

export default {
  components: { PageHead, ProceduresList, Separator },
  setup() {
    // #############################
    // Content displayed on the website
    // #############################
    const headImg = ref(
      `https://images.unsplash.com/photo-1468493858157-0da44aaf1d13?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2942&q=80`
    );
    const headTitle = ref(`Proceduri`);
    const subtitle = ref(
      "conform ordinului 2341/2023"
    );
    // #############################
    // #############################

    return { headImg, subtitle, headTitle };
  },
};
</script>